// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.5.0",
  "buildNumber": "19875",
  "gitSha": "9a6c1e01b63f5e811ed3d4dfb054947624801578",
  "fullVersion": "24.5.0-19875",
  "formattedVersion": "24.5.0 (19875-9a6c1e01b63f5e811ed3d4dfb054947624801578)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
